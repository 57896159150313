<template>
  <table width="100%" cellspacing="0" cellpadding="0" v-if="workspaceID">
    <tr style="vertical-align: top;">
      <td 
        style="width: auto; padding: 20px 0 20px 10px;margin: 0;"
      >
      <div
        style="min-height:calc(100vh - 104px);background-color:white;border-radius:4px 0 0 4px;"
      >
        
        <v-date-picker
          :key="selectedDate"
          v-model="selectedDate"
          :first-day-of-week="0"
          :locale="locale"
          :event-color="checkEventsColors"
          :events="checkEvents"
          :picker-date.sync="pickerDate"
          flat
          style="border-radius: 4px 0 0 0;"
        />
        <div
          class="info-cal"
        >
          <v-alert
            color="#CCCCCC80"
          >
              <v-icon color="green">mdi-circle-medium</v-icon> {{$t('booking.reservations.withBookings', locale)}}
              <br />
              <v-icon color="black">mdi-circle-medium</v-icon> {{$t('booking.reservations.withCompleteBookings', locale)}}
              <br />
              <v-icon color="red">mdi-circle-medium</v-icon> {{$t('booking.reservations.withCancelBookings', locale)}}
              <br />
              <v-icon color="#cccccc">mdi-circle-medium</v-icon> {{$t('booking.reservations.withEvents', locale)}}
          </v-alert>
          <export-pdf 
            v-if="workspaceID && selectedDate"
            :workspaceID="workspaceID"
            :query="query"
          />
        </div>
      </div>
      </td>
      <td style="width: 100%;">
        <v-layout wrap>
          <v-flex xs7 style="padding:20px 10px 20px 0;">
            <list
              v-if="workspaceID && selectedDate"
              :workspaceID="workspaceID"
              :selectedDate="selectedDate"
              :locale="locale"
              :onEdit="handleEditItem"
              :updateData="updateData"
              :onChangeQuery="handleChangeQuery"
            />
          </v-flex>
          <v-flex xs5
            style="padding:20px 20px 20px 10px;"
          >
            <v-card
              style="height:calc(100vh - 104px);overflow-y: auto;overflow-x: hidden;scrollbar-width: thin !important;"
              elevation="0"
            >
              <v-card-text>
                <ticket
                  v-if="bookingTicketData"
                  :booking="bookingTicketData"
                  :onClose="handleClose"
                  :locale="locale"
                />
                <no-edited
                  v-else-if="!editedID"
                  :selectedDate="selectedDate"
                  :onAdd="handleAddItem"
                />
                <booking-reservation-form
                  v-else
                  :editedID="editedID"
                  :event="editedEvent"
                  :onAdd="handleAdd"
                  :onUpdate="handleUpdate"
                  :onDelete="handleDelete"
                  :onClose="handleClose"
                />
                <!--
                <form-reservation
                  v-else
                  :event="editedEvent"
                  :editedID="editedID"
                  :locale="locale"
                  :onClose="handleClose"
                />
                -->
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </td>
    </tr>
  </table>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
import BookingReservationForm from '@/components/common-components/bookingReservationForm/Index'
import List from '@/components/reservationsList/Index'
import NoEdited from './NoEdited'
import Ticket from './Ticket'
import ExportPdf from './ExportPdf'
//import FormReservation from './formReservation/Index'
export default {
  components: {
    BookingReservationForm,
    List,
    NoEdited,
    Ticket,
    ExportPdf,
    //FormReservation,
  },
  data: () => ({
    workspaceID: null,
    activeDates: [],
    selectedDate: utils.checkDate(new Date()),
    editedID: null,
    updateData: null,
    editedEvent: null,
    pickerDate: null,
    bookingTicketData: null,
    query: null,
  }),
  computed: {
    ...mapState('app',['locale', 'bookingWorkspace', 'bookingRefreshReservationList']),
    queryDate () {
      return this.$route.query.date
    },
  },
  watch: {
    bookingWorkspace (v) {
      this.workspaceID = v
      this.getActiveDates()
    },
    pickerDate () {
      this.getActiveDates()
    },
    selectedDate (v) {
      this.editedID = null
      if (this.queryDate && this.queryDate.length >  0)
        window.location.href = window.location.href.split('?date=') [0].concat(`?date=${v}`)
     /* 
      //if (this.queryDate) this.$router.replace({ name: "user-view", params: {id:"123"}, query: {q1: "q1"} })
      if (this.queryDate && this.queryDate.length >  0) {
        this.respondToRouteChanges = false;
        this.$router.replace({ query: { date: v } })
              .finally(() => {
                this.respondToRouteChanges = true
              })
      } 
       // this.$router.replace({ query: {date: v} })
    */
    },
    bookingRefreshReservationList () {
      this.getActiveDates()
    },
  },
  mounted () {
    this.workspaceID = this.bookingWorkspace

    this.pickerDate = this.queryDate ? 
      this.queryDate.substring(0, 7) :
      new Date().toISOString().substring(0, 7)

    this.getActiveDates()
    if ( this.queryDate && this.queryDate.length > 0) this.selectedDate = this.queryDate
  },
  methods: {
    handleChangeQuery (v) {
      this.query = v
    },
    getActiveDates () {
      if (!this.workspaceID || !this.pickerDate) return
      api.getAllWithoutLimit ('booking', `v1/private/workspaces/${this.workspaceID}/reserved-days`, false, `&date=${this.pickerDate}`)
        .then(response => {
          this.activeDates = response.map(item => {
            return {
              date: item.Date.split('T').shift(),
              book: item.TotalBook && item.TotalBook > 0,
              free: item.TotalFree && item.TotalFree > 0,
              cancel: item.TotalCancel && item.TotalCancel > 0,
            }
          })
        })
    },
    handleAddItem (event) {
      this.editedEvent = event
      this.editedID = 'new'
    },
    handleEditItem (reservationID, event) {
      this.editedEvent = event
      this.editedID = reservationID
    },
    handleGetReservationTicket (v) {
      api.getItem ('booking', `v1/private/reservations/`, v)
        .then((response) => {
          this.bookingTicketData = response
        })
        .catch(error => {
          console.log('error', error)
        })

    },
    handleAdd (body) {
      api.addItem ('booking', `v1/private/reservations/`, body)
        .then((response) => {
          if (response.data.status === 'KO') {
            this.$store.state.app.bookingUpdateAvailability_resource = utils.getNewID()
            alert(this.$t(`booking.reservations.messages.${response.data.error}`, this.locale))
            return
          }
          this.handleGetReservationTicket(response.data)
          this.$store.state.app.bookingRefreshReservationList = utils.getNewID()
          if (response) this.handleClose('add', response.data)
        })
        .catch(error => {
          alert(error)
          console.log('errorjjj', error)
        })
    },
    handleUpdate (id, body) {
      api.updateItem ('booking', `v1/private/reservations/`, id , body)
        .then((response) => {
            this.$store.state.app.bookingUpdateAvailability_resource = utils.getNewID()
          if (response.data.status === 'KO') {
            alert(this.$t(`booking.reservations.messages.${response.data.error}`, this.locale))
            return
          }
          this.handleGetReservationTicket(response.data)
          this.$store.state.app.bookingRefreshReservationList = utils.getNewID()
          if (response) this.handleClose('add', response.data)
        })
        .catch(error => {
          alert(error)
          console.log('error', error)
        })

    },
    handleDelete (id) {
      api.deleteItem('booking', `v1/private/reservations/`, id)
        .then(response => {
          if(response && response.data.status === 'OK') this.handleClose('delete', id)
        })
    },
    handleClose (action, data) {
      this.bookingTicketData = null
      this.editedEvent = null
      this.editedID = null
      this.updateData = {
        data,
        LMD: new Date(),
        action,
      }
    },
    checkEvents (date) {
      if (!this.activeDates) return false
      const aux = this.activeDates.filter(x => x.date === date).shift()
      if (!aux) return false

      const result = []
      if (!aux.free && aux.book) result.push('#000000')
      else if (aux.book) result.push('green')
      if (aux.cancel) result.push('red')
      if (!aux.book && !aux.cancel) result.push('#CCCCCC')
      return result.length > 0 ? result : false
      /*
      const [,, day] = date.split('-')
      if ([1, 19, 22].includes(parseInt(day, 10))) return ['red', 'green']
      return false
      */
    },
    checkEventsColors () {
      return null
      //return date[9] % 2 ? 'red' : 'yellow'
    },
  },
}
</script>
<style>
.info-cal {
  padding: 20px;
}
.info-cal .v-alert {
  padding: 10px;
}
.info-cal .v-alert__content {
  font-size: 0.7rem !important;
}
</style>

