<template>
  <v-layout wrap>
    <v-flex xs12>
      <ticket :booking="booking" :locale="locale" />
    </v-flex>
    <v-flex xs12 style="padding: 20px">
      <v-btn 
        @click="onClose" 
        color="primary"
        block
      >{{$t('common.close', locale)}}</v-btn>
    </v-flex>
  </v-layout>
</template>
<script>
import Ticket from '@/components/common-components/reservation/Ticket'
export default {
  components: {
    Ticket,
  },
  props: {
    booking: {
      type: Object,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },

}
</script>

